import { MatomoEventType, sendMatomoEvent } from "~/util/sendMatomoEvent";

export function useLinkHandler() {
  const handleOutgoingDescriptionLinks = function (e: Event) {
    for (let { target } = e; target && target !== document; target = target.parentNode) {
      if (target.matches("a") && (target.href.startsWith("http") || target.href.startsWith("mailto"))) {
        sendMatomoEvent({ linkType: "link", sourceUrl: target.href, type: MatomoEventType.trackLink });
        // noreferrer implies noopener @see https://mdn.io/noreferrer
        window.open(target.href, "_blank", "noreferrer");
        break;
      }
    }
  };

  return handleOutgoingDescriptionLinks;
}
